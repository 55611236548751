<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import { get_Appoint_Dashboard_2 } from '@/api/reservesystemdashboard'
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'

export default {
  mixins: [resize],
  props: {
    partner:{
      type: String,
      require: true
    },
    select_start_date:{
      type: String,
      require: true
    },
    select_end_date:{
      type: String,
      require: true
    },
    search_period_date:{
      type: String,
      require: true
    },
    className: {
      type: String,
      default: 'chart_reserve'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
  },
    watch:{
    search_period_date:{
      handler: async function(val, oldVal) {
            await this.get_Appoint_Dashboard_2()
            await this.initChart_type()
        },
    },
    immediate:true,
  },
  async mounted() {
    console.log('mounted PieChartReserce start ')
    console.log(this.partner)
    console.log(this.select_start_date)
    console.log(this.select_end_date)
    console.log(this.search_period_date)
    await this.get_Appoint_Dashboard_2()
    await this.initChart_type()
    console.log('mounted PieChartReserce end')
  },
  data() {
    return {
      chart: null,
      //預約來源統計
      get_reserve_type_2_list:[],
      //預約來源統計實際對應資料
      get_reserve_data_type_2_list:[],
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    async get_Appoint_Dashboard_2(){
      var payload = ''
      payload ='?partner_id='+this.partner+'&search_type='+this.select_class_range+
      '&search_start_date='+this.select_start_date+'&search_end_date='+this.select_end_date
      await get_Appoint_Dashboard_2(payload).then(async (resp)=>{
        //得知預約來源統計
        this.get_reserve_type_2_list = resp.get_reserve_type_2_list
        //預約來源統計實際對應資料
        this.get_reserve_data_type_2_list = resp.get_reserve_data_type_2_list
      })
    },
    initChart_type() {
    this.chart = null
      this.chart = echarts.init(this.$el, 'macarons')
      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: this.get_reserve_type_2_list
        },
        series: [
          {
            name: '預約來源統計',
            type: 'pie',
            roseType: 'radius',
            radius: [15, 95],
            center: ['50%', '40%'],
            data: this.get_reserve_data_type_2_list,
            animationEasing: 'cubicInOut',
            animationDuration: 2900
          }
        ]
      })
    }
  }
}
</script>
