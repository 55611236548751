import request from '@/utils/request'

export function getService(query) {
    console.log("get_service",typeof(query))
    // console.log("get_question", query)
    if(typeof(query)=="number"){
        return request({
            url: '/api/appoint_service_record/' + query,
            method: 'get',
        })
    }
    else{
        return request({
            url: '/api/appoint_service_record/' + query,
            method: 'get',
        })
    }
}
export function getServiceCategory(page) {
    return request({
        url: '/api/appoint_service_category_record/' + page,
        method: 'get',
    })
}
export function insert_update_service(data) {
  // console.log(data);
    return request({
        url: '/api/appoint_service_record',
        method: 'post',
        data
    })
}
export function insert_update_service_category(data) {
    // console.log(data);
    return request({
        url: '/api/appoint_service_category_record',
        method: 'post',
        data
    })
}

export function edit_delete_service_photo(data) {
    // console.log(data);
    return request({
        url: '/api/edit_delete_service_photo_record',
        method: 'post',
        data
    })
}

export function deleteServiceCategory(data){
    return request({
        url: '/api/delete_service_category_record',
        method: 'post',
        data
    })
}

export function postServiceOrderList(data){
    return request({
        url: '/api/appoint_service_order_record',
        method: 'post',
        data
    })
}

export function postServiceCategoryOrderList(data){
    return request({
        url: '/api/appoint_service_category_order_record',
        method: 'post',
        data
    })
}