import { render, staticRenderFns } from "./BoxCard.vue?vue&type=template&id=7eb9b6a5&scoped=true&"
import script from "./BoxCard.vue?vue&type=script&lang=js&"
export * from "./BoxCard.vue?vue&type=script&lang=js&"
import style0 from "./BoxCard.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./BoxCard.vue?vue&type=style&index=1&id=7eb9b6a5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7eb9b6a5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/andy1229/專案/vue_後端網頁介面參考/預約系統/原動力物理治療師專案/shopstore_reserve/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7eb9b6a5')) {
      api.createRecord('7eb9b6a5', component.options)
    } else {
      api.reload('7eb9b6a5', component.options)
    }
    module.hot.accept("./BoxCard.vue?vue&type=template&id=7eb9b6a5&scoped=true&", function () {
      api.rerender('7eb9b6a5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/reserve_system_dashboard/components/BoxCard.vue"
export default component.exports