import request from '@/utils/request'


//得知預約訂單資料
export function getAppointOrderList(query) {
  console.log('get_appoint_order_list', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/appoint_order_record/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/appoint_order_record/' + query,
      method: 'get'
    })
  }
}

//得知預約資料（貴族）
export function getAppointReserveList(query) {
  console.log('get_check_reserve_system_health_reserve', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/check_reserve_system_health_reserve/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/check_reserve_system_health_reserve/' + query,
      method: 'get'
    })
  }
}

//新增、修改預約訂單資料
export function postAppointOrderList(data) {
  console.log('post_appoint_order_list', typeof (data))
    return request({
      url: '/appoint_frontend/appoint_reserve_system_health_reserve',
      method: 'post',
      data
    })
}
//多筆修改預約訂單狀態
export function BatchAppointOrderStatus(data) {
  console.log('batch_appoint_order_status', typeof (data))
    return request({
      url: '/api/batch_order_status_record',
      method: 'post',
      data
    })
}

//多筆修改預約報到狀態
export function BatchAppointJoinStatus(data) {
  console.log('batch_appoint_join_status', typeof (data))
    return request({
      url: '/api/batch_join_status_record',
      method: 'post',
      data
    })
}

//查詢身分證床號
export function searchAppointPatientName(data) {
  console.log('searchAppointPatientName', typeof (data))
    return request({
      baseURL:'https://hciot.com.tw:6810',
      url: '/api/reserve/getresname',
      method: 'post',
      data
    })
}